<template>
  <div class="home">
    <h1>Liste des histoires</h1>
    <div class="container mx-auto">
    <ul class="flex flex-wrap">
      <li v-for="scenario in scenarios" :key="scenario.to"
          class="md:w-1/6 block p-4"
      >
        <router-link :to="scenario.to">{{ scenario.name }}</router-link> 
      </li>
    </ul>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },data() {
    return { 
      scenarios : [
        {
          name : 'Le mythe de l\'Atlantide',
          to : '/le-mythe-de-l-atlantide'
        },
        {
          name : 'Le mythe de l\'Atlantide 2 ',
          to : '/le-mythe-de-l-atlantide'
        },
      ],
    }
  },
  mounted() {
    console.log(this.scenarios) 
  }

}
</script>
