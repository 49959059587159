import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './index.css'

const app = createApp(App)

// Créez une variable globale $router
app.config.globalProperties.$router = router

app.use(store).use(router).mount('#app')



