import { createStore } from "vuex";
import storePersistence from "./storePersistence";

// Récupérez les données depuis le localStorage
const serializedData = localStorage.getItem("myStoreData");

// Initialisez l'état du store avec les données récupérées du localStorage
const initialState = serializedData ? JSON.parse(serializedData) : {};

export default createStore({
  state() {
    return {
      timer: initialState.timer > 0 ? initialState.timer : 0,
      isPlaying: initialState.isPlaying,
      currentScenarioId: initialState.currentScenarioId,
      currentStepId:
        initialState.currentStepId > 0 ? initialState.currentStepId : 1,
      scenarios: [
        {
          id: 1,
          title: "Le Mythe de L'Atlantide",
          assetsDir: "01-Atlantide/",
          steps: [
            {
              id: 1,
              name: "La lettre La lettre mystérieuse",
              bgImage: "1-atlantide.jpg",
              bgMusic: "music.mp3",
              solution : "2;A",
              description : "todo",
            },
            {
              id: 2,
              name: "La bibliothèque d'Alexandrie",
              bgImage: "2-library-dark.png",
              bgMusic: "music.mp3",
              solution : "",
              description : "Lorsque vous pénétrez dans la bibliothèque d'Alexandrie, un silence sacré vous enveloppe. Les étagères chargées de connaissances semblent revivre, chaque livre renfermant les échos du passé. L'air est empreint de la richesse des mots et des récits, comme si les murs eux-mêmes murmuraient des secrets millénaires. Vous vous tenez au seuil d'une révélation profonde, prêts à dénouer les énigmes soigneusement enfouies dans ces pages antiques, laissant derrière vous l'agitation du monde moderne pour plonger dans un royaume de sagesse intemporelle.",
            },
            {
              id: 3,
              name: "La bibliothèque d'Alexandrie",
              bgImage: "3-library-light.png",
              bgMusic: "music.mp3",
              solution : "",
              description : "todo",
            },
            {
              id: 4,
              name: "Le sous-sol",
              bgImage: "4-cave.png",
              bgMusic: "music.mp3",
              solution : "",
              description : "Poussés par votre soif insatiable de connaissance, vous soulevez avec précaution cette porte dissimulée. Une brise fraîche et humide vous accueille, comme un doux murmure des âges révolus. Devant vous, une échelle en fer forgé s'étire en profondeur, un passage entre les mondes. À chaque échelon descendu, l'obscurité ambiante se dissipe peu à peu, dévoilant des murs de pierre ornés d'inscriptions anciennes, tels des gardiens silencieux d'une histoire enfouie depuis des temps immémoriaux.",
            },
          ],
          characters: [
            {
              id: 1,
              name: "Dr. Olivia Carter",
              availableAt: 1,
              thumbnail: "olivia-thumb.png",
              img: "olivia.png",
              action : null,
              dialogues: [
                {
                  step: 1,
                  text: "Enchantée de vous rencontrer enfin, explorateurs. Je suis le <strong>Dr. Olivia Carter</strong>, une passionnée d'archéologie et de mythologie. Les symboles marins que vous tenez entre vos mains ont un lien bien plus profond que ce qu'ils semblent être. Ils sont comme les pièces manquantes d'un puzzle ancestral. Si je devais vous donner un conseil, ce serait de prêter une attention particulière aux <strong>légendes sur Poséidon</strong>. Parfois, le passé refait surface là où on ne l'attend pas.",
                },
                {
                  step: 3,
                  text: "Quelle belle bibliothèque !",
                },
              ],
            },
            {
              id: 2,
              name: "Pr. Philippe Lefebvre",
              availableAt: 1,
              thumbnail: "philippe-thumb.png",
              img: "philippe.png",
              action : null,
              dialogues: [
                {
                  step: 1,
                  text: "Bonjour, je me prénomme David Lefebvre. Mon expertise réside dans l'exploration des énigmes du passé, et j'ai consacré ma vie à décrypter les mystères enfouis dans les sables du temps. L'histoire de l'Égypte ancienne m'a toujours fasciné, et je ne peux m'empêcher de penser que des fils cachés de cette histoire pourraient se dévoiler au fur et à mesure que vous avancez. Gardez un œil attentif et laissez-vous guider par votre intuition.",
                },
              ],
            },
            {
              id: 3,
              name: "Alexandros Panagiotopoulos",
              availableAt: 1,
              thumbnail: "alexandros-thumb.png",
              img: "alexandros.png",
              action : null,
              dialogues: [
                {
                  step: 1,
                  text: "Bonjour, membres de l'équipe. C'est moi, Alexandre Panagiotopoulos, le coordinateur de notre aventure. Si je peux vous suggérer une piste, c'est de jeter un coup d'œil aux traces laissées par les civilisations antiques autour de la Méditerranée. Qui sait ce que vous pourriez déterrer ?",
                },
              ],
            },
            {
              id: 4,
              name: "Capitaine Pieter Van der Meer",
              availableAt: 1,
              thumbnail: "pieter-thumb.png",
              img: "pieter.png",
              action : 'travel',
              dialogues: [
                {
                  step: 1,
                  text: "Salutations. C'est moi, le Capitaine Pieter Van der Meer, un marin chevronné depuis de nombreuses années, avec une connaissance intime des flots de la mer Méditerranée. Les mystères de l'Atlantide ont toujours enflammé ma passion. Guidé par cette quête, je serais honoré de vous mener aux coordonnées que vous me confierez, à travers les vagues et le temps.",
                },
              ],
            },
            {
              id: 5,
              name: "Finn Foster",
              availableAt: 2,
              thumbnail: "finn-thumb.png",
              img: "finn.jpg",
              action : null,
              dialogues: [
                {
                  step: 2,
                  text: "Cette bibliothèque est construite sur les ruines de l’ancienne bibliothèque détruite il y a bien longtemps durant l’antiquité ne laissant aucune trace des trésors que renfermaient ce lieu exceptionnel. Du moins c’est ce que l’on pense….. Encore faut il trouver le moyen de mettre en lumière le passage pour y accéder <br/> Une dernière chose, ce document pourrait sûrement vous intéresser. Je l’ai trouvé dans un livre qui raconte une vieille légende sur les sirènes. Mais je ne me souviens plus de quoi il s’agit",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  getters: {
    getScenario(state) {
      return state.scenarios.find(
        (scenario) => scenario.id === state.currentScenarioId
      );
    },
  },
  mutations: {


    setTimer(state, value) {
      state.timer = value;
    },
    setIsPlaying(state, value) {
      state.isPlaying = value;
    },
    setCurrentStepId(state, stepIndex) {
      state.currentStepId = stepIndex;
    },
    setCurrentScenarioId(state, scenarioId) {
      state.currentScenarioId = scenarioId; // Mutation pour définir l'ID du scénario actuel
    },
    updateTimer(state) {
      state.timer++;
    },
    nextStepId(state){
      state.currentStepId++
    }
  },
  actions: {
    startTimer({ commit }) {
      setInterval(() => {
        commit("updateTimer");
      }, 1000); // Met à jour le timer toutes les 1000 millisecondes (1 seconde)
    },
    startScenario({ commit }, scenarioId) {
      commit("setCurrentStepId", 1); //ATTENTION : à mettre à 1 en prod
      commit("setIsPlaying", 1);
      commit("setTimer", 0);
      commit("setCurrentScenarioId", scenarioId);
    },
    nextStep({ commit }) {
      commit("nextStepId");
    }

  },
  modules: {},
  plugins: [storePersistence],
});
