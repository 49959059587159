export default store => {
    // Enregistrer le store dans le localStorage à chaque mutation
    store.subscribe((mutation, state) => {
      // Utilisez JSON.stringify pour convertir l'état en chaîne JSON
      const serializedState = JSON.stringify(state)
      
      // Enregistrez la chaîne JSON dans le localStorage avec une clé spécifique
      localStorage.setItem('myStoreData', serializedState)
    })
  }
  