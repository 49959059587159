<template>
  <router-view/>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


@media only screen and (orientation:landscape) {
  #app {
    height: 100vh;
    width: 100vw;
  }
}

@media only screen and (orientation:portrait) {
    #app {
      transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: fixed;
    top: 100%;
    left: 0;
  }
}
</style>
